import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import { setMaster, masterContants } from "views/app-views/master/redux";
import { customersContants } from "./Constants";
import { CustomersServices } from "services";

export function* getAllCustomersSaga() {
  yield takeEvery(
    [masterContants.GET_ALL_MASTER, customersContants.GET_ALL_CUSTOMERS],
    function* ({ payload }) {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
        };
        const { data } = yield call(CustomersServices.getAll, reqBody);
        yield put(setMaster({ customers: [...data.rows] }));
      } catch (error) {
        console.log("getAllCustomersSaga ", error);
      }
    },
  );
}

export default function* rootSaga() {
  yield all([fork(getAllCustomersSaga)]);
}
