import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import { setMaster, masterContants } from "views/app-views/master/redux";
import { stockUnitsContants } from "./Constants";
import { StockUnitsServices } from "services";

export function* getAllStockUnitsSaga() {
  yield takeEvery(
    [masterContants.GET_ALL_MASTER, stockUnitsContants.GET_ALL_STOCK_UNITS],
    function* ({ payload }) {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
        };
        const { data } = yield call(StockUnitsServices.getAll, reqBody);
        yield put(setMaster({ stockUnits: [...data.rows] }));
      } catch (error) {
        console.log("getAllStockUnitsSaga ", error);
      }
    },
  );
}

export default function* rootSaga() {
  yield all([fork(getAllStockUnitsSaga)]);
}
