import { authContants } from '.';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  activeUser: localStorage.getItem(authContants.ACTIVE_USER),
};

export const authReducers = (state = initState, action) => {
  switch (action.type) {
    case authContants.AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/',
        activeUser: action.activeUser,
      };
    case authContants.SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case authContants.HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };
    case authContants.SIGNOUT_SUCCESS: {
      return {
        ...state,
        activeUser: null,
        redirect: '/',
        loading: false,
      };
    }
    case authContants.SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    default:
      return state;
  }
};
