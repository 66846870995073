import { stockContants } from ".";

export const getAllStockCategories = () => ({
  type: stockContants.GET_ALL_STOCK_CATEGORIES,
});

export const getAllBrands = () => ({
  type: stockContants.GET_ALL_BRAND,
});

export const getAllSellingPrices = () => ({
  type: stockContants.GET_ALL_SELLING_PRICES,
});

export const getAllGoods = () => ({
  type: stockContants.GET_ALL_GOODS,
});

export const getAllInventories = () => ({
  type: stockContants.GET_ALL_INVENTORIES,
});

export const getAllGoodUnits = () => ({
  type: stockContants.GET_ALL_GOODUNITS,
});
