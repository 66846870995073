import { authContants } from '.';

export const signIn = (user) => {
  return {
    type: authContants.SIGNIN,
    payload: user,
  };
};

export const authenticated = (activeUser) => {
  return {
    type: authContants.AUTHENTICATED,
    activeUser,
  };
};

export const signOut = () => {
  return {
    type: authContants.SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: authContants.SIGNOUT_SUCCESS,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: authContants.SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: authContants.HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: authContants.SHOW_LOADING,
  };
};
