import FetchInterceptor from "configs/FetchInterceptor";

class CashierServices {
  static async getList(requestBody) {
    const apiUrl = "/core/v2/cashier/booking/list";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async cancel(requestBody) {
    const apiUrl = "/core/v2/cashier/booking/cancel";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async remove(requestBody) {
    const apiUrl = "/core/v2/cashier/booking/remove";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async calculate(requestBody) {
    const apiUrl = "/core/v2/cashier/booking/calculate";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async payment(requestBody) {
    const apiUrl = "/core/v2/cashier/booking/payment";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async cod(requestBody) {
    const apiUrl = "/core/v2/cashier/booking/cod";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
}

export default CashierServices;
