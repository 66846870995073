import { SET_STATE_USER, SET_DEFAULT_USER } from 'redux/constants/User';

export const setStateUser = (payload) => ({
  type: SET_STATE_USER,
  payload,
});

export const setDefaultUser = () => ({
  type: SET_DEFAULT_USER,
});
