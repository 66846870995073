import FetchInterceptor from "configs/FetchInterceptor";

class PurchaseNotesServices {
  static async getAll(requestBody) {
    const apiUrl = "/core/v1/purchase/note/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addDebitNote(requestBody) {
    const apiUrl = `/core/v1/purchase/note/debit/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addCreditNote(requestBody) {
    const apiUrl = "/core/v1/purchase/note/credit/add";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getById(id) {
    const apiUrl = `/core/v1/purchase/note/get/${id}`;
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async update(type, requestBody) {
    const apiUrl = `/core/v1/purchase/note/${type.toLowerCase()}/update`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }
  static async getPdf(id) {
    const apiUrl = `/core/v1/purchase/note/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default PurchaseNotesServices;
