import { masterContants } from ".";

export const setMaster = (payload) => ({
  type: masterContants.SET_STATE_MASTER,
  payload,
});

export const setDefaultMaster = () => ({
  type: masterContants.SET_DEFAULT_MASTER,
});

export const getAllMaster = (payload) => ({
  type: masterContants.GET_ALL_MASTER,
  payload,
});
