export const authContants = {
  SIGNIN: 'SIGNIN',
  AUTHENTICATED: 'AUTHENTICATED',
  SIGNOUT: 'SIGNOUT',
  SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
  SHOW_AUTH_MESSAGE: 'SHOW_AUTH_MESSAGE',
  HIDE_AUTH_MESSAGE: 'HIDE_AUTH_MESSAGE',
  SHOW_LOADING: 'SHOW_LOADING',
  ACTIVE_USER: 'active_user',
};
