import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import { setMaster, masterContants } from "views/app-views/master/redux";
import { stockContants } from "../redux";
import {
  StockCategoryServices,
  BrandsServices,
  SellingPricesServices,
  GoodsServices,
  InventoriesServices,
} from "services";

export function* getAllStockCategoriesSaga() {
  yield takeEvery(
    [stockContants.GET_ALL_STOCK_CATEGORIES, masterContants.GET_ALL_MASTER],
    function* () {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
          parentId: null,
          tier: null,
        };

        const { data } = yield call(StockCategoryServices.getAll, reqBody);
        yield put(setMaster({ stockCategories: [...data.rows] }));
      } catch (error) {
        console.log("getAllStockCategoriesSage ", error);
      }
    },
  );
}

export function* getAllBrandsSaga() {
  yield takeEvery([stockContants.GET_ALL_BRAND, masterContants.GET_ALL_MASTER], function* () {
    try {
      const getCurrentUser = (state) => state.user;
      const { currentUser } = yield select(getCurrentUser);

      const reqBody = {
        limit: 999999999999,
        offset: 0,
        branchId: currentUser.employee.branchId,
      };

      const { data } = yield call(BrandsServices.getAll, reqBody);
      yield put(setMaster({ brands: [...data.rows] }));
    } catch (error) {
      console.log("getAllBrands ", error);
    }
  });
}

export function* getAllSellingPricesSaga() {
  yield takeEvery(
    [stockContants.GET_ALL_SELLING_PRICES, masterContants.GET_ALL_MASTER],
    function* () {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
        };

        const { data } = yield call(SellingPricesServices.getAll, reqBody);
        yield put(setMaster({ sellingPrices: [...data.sellingPrices] }));
      } catch (error) {
        console.log("getAllBrands ", error);
      }
    },
  );
}

export function* getAllGoodsSaga() {
  yield takeEvery([stockContants.GET_ALL_GOODS, masterContants.GET_ALL_MASTER], function* () {
    try {
      const getCurrentUser = (state) => state.user;
      const { currentUser } = yield select(getCurrentUser);

      const reqBody = {
        limit: 999999999999,
        offset: 0,
        branchId: currentUser.employee.branchId,
      };

      const { data } = yield call(GoodsServices.getAll, reqBody);
      yield put(setMaster({ goods: [...data.rows] }));
    } catch (error) {
      console.log("getAllBrands ", error);
    }
  });
}

export function* getAllInventoriesSaga() {
  yield takeEvery([stockContants.GET_ALL_INVENTORIES, masterContants.GET_ALL_MASTER], function* () {
    try {
      const getCurrentUser = (state) => state.user;
      const { currentUser } = yield select(getCurrentUser);

      const reqBody = {
        limit: 999999999999,
        offset: 0,
        branchId: currentUser.employee.branchId,
      };

      const { data } = yield call(InventoriesServices.getAll, reqBody);
      yield put(setMaster({ inventories: [...data.inventories] }));
    } catch (error) {
      console.log("getAllBrands ", error);
    }
  });
}

export function* getAllGoodUnitsSaga() {
  yield takeEvery([stockContants.GET_ALL_GOODUNITS, masterContants.GET_ALL_MASTER], function* () {
    try {
      const getCurrentUser = (state) => state.user;
      const { currentUser } = yield select(getCurrentUser);

      const reqBody = {
        limit: 999999999999,
        offset: 0,
        branchId: currentUser.employee.branchId,
      };

      const { goodUnits } = yield call(GoodsServices.getAllGoodUnits, reqBody);
      yield put(setMaster({ goodUnits: [...goodUnits] }));
    } catch (error) {
      console.log("getAllBrands ", error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAllStockCategoriesSaga),
    fork(getAllBrandsSaga),
    fork(getAllSellingPricesSaga),
    fork(getAllGoodsSaga),
    fork(getAllInventoriesSaga),
    fork(getAllGoodUnitsSaga),
  ]);
}
