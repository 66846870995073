import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectLoading = createSelector([selectAuth], (auth) => auth.loading);

export const selectMessage = createSelector([selectAuth], (auth) => auth.message);

export const selectShowMessage = createSelector([selectAuth], (auth) => auth.showMessage);

export const selectActiveUser = createSelector([selectAuth], (auth) => auth.activeUser);

export const selectRedirect = createSelector([selectAuth], (auth) => auth.redirect);
