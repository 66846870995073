import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authReducers as auth } from "views/auth-views/login/redux";
import { masterReducers as master } from "views/app-views/master/redux";
import { theme } from "./Theme";
import { user } from "./User";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
  blacklist: ["theme", "auth", "master"],
};

const rootReducer = combineReducers({
  auth,
  theme,
  user,
  master,
});

export default persistReducer(persistConfig, rootReducer);
