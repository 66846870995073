import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import { setMaster, masterContants } from "views/app-views/master/redux";
import { chartOfAccountContants } from "./Constants";
import { ChartOfAccountService, BranchServices } from "services";

export function* getAllChartOfAccountsSaga() {
  yield takeEvery(
    [masterContants.GET_ALL_MASTER, chartOfAccountContants.GET_ALL_CHART_OF_ACCOUNT],
    function* ({ payload }) {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);
        const branchDetail = yield call(BranchServices.getBranch, currentUser.employee.branchId);
        const { data } = yield call(
          ChartOfAccountService.getAll,
          `?store_id=${branchDetail.data.name}`,
        );
        yield put(setMaster({ chartOfAccounts: [...data] }));
      } catch (error) {
        console.log("getAllChartOfAccountsSaga ", error);
      }
    },
  );
}

export function* getAllCoaListSaga() {
  yield takeEvery(
    [masterContants.GET_ALL_MASTER, chartOfAccountContants.GET_ALL_COA_LIST],
    function* ({ payload }) {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
        };
        const { data } = yield call(ChartOfAccountService.getList, reqBody);
        yield put(setMaster({ coaList: [...data.rows] }));
      } catch (error) {
        console.log("getAllCoaListSaga ", error);
      }
    },
  );
}

export default function* rootSaga() {
  yield all([fork(getAllChartOfAccountsSaga), fork(getAllCoaListSaga)]);
}
