import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import getAllBranch from "../branch/redux/Sagas";
import getAllChartOfAccounts from "../chart-of-account/redux/Sagas";
import getAllStockCategories from "../stock/redux/Sagas";
import getAllStockUnitsSaga from "../stock-unit/redux/Sagas";
import getAllWarehouseSaga from "../warehouse/redux/Sagas";
import getAllSuppliersSaga from "../supplier/redux/Sagas";
import getAllCustomersSaga from "../customer/redux/Sagas";
import getAllEdcRatesSaga from "../edc/redux/Sagas";
import { setMaster, masterContants } from ".";
import {
  BranchServices,
  WarehouseServices,
  CustomersServices,
  GoodsServices,
  InventoriesServices,
  SupplierServices,
  SellingPricesServices,
  ChartOfAccountService,
  EdcServices,
} from "services";

export function* getAllMasterData() {
  yield takeEvery([masterContants.GET_ALL_MASTER], function* ({ payload }) {
    try {
      const { employee } = payload;
      const reqBody = {
        limit: 999999999999,
        offset: 0,
        branchId: employee.branchId,
      };
      const branchDetail = yield call(BranchServices.getBranch, employee.branchId);
      const [
        branchs,
        warehouses,
        goods,
        customers,
        inventories,
        goodUnits,
        suppliers,
        sellingPrices,
        chartOfAccounts,
        coaList,
        edcRates,
      ] = yield all([
        getBranchs(reqBody),
        getWarehouses(reqBody),
        getGoods(reqBody),
        getCustomers(reqBody),
        getInventories(reqBody),
        getGoodUnits(reqBody),
        getSuppliers(reqBody),
        getSellingPrices(reqBody),
        getChartOfAccounts(`?store_id=${branchDetail.data.name}`),
        getChartOfAccountList(),
        getEdcRates(reqBody),
      ]);
      yield put(
        setMaster({
          masterData: {
            branchs,
            warehouses,
            customers,
            goods,
            inventories,
            goodUnits,
            suppliers,
            sellingPrices,
            chartOfAccounts,
            coaList,
            edcRates,
          },
        }),
      );
    } catch (error) {
      console.log("getAllMasterData ", error);
    }
  });
}

const getBranchs = async (reqBody) => {
  let result = [];
  try {
    const { data } = await BranchServices.getAllBranch(reqBody);
    result = data.rows;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getWarehouses = async (reqBody) => {
  let result = [];
  try {
    const { data } = await WarehouseServices.getAll(reqBody);
    result = data.rows;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getCustomers = async (reqBody) => {
  let result = [];
  try {
    const { data } = await CustomersServices.getAll(reqBody);
    result = data.rows;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getGoods = async (reqBody) => {
  let result = [];
  try {
    const { data } = await GoodsServices.getAll(reqBody);
    result = data.rows;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getInventories = async (reqBody) => {
  let result = [];
  try {
    const { data } = await InventoriesServices.getAll(reqBody);
    result = data.inventories;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getGoodUnits = async (reqBody) => {
  let result = [];
  try {
    const { goodUnits } = await GoodsServices.getAllGoodUnits(reqBody);
    result = goodUnits;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getSuppliers = async (reqBody) => {
  let result = [];
  try {
    const { data } = await SupplierServices.getAll(reqBody);
    result = data.rows;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getSellingPrices = async (reqBody) => {
  let result = [];
  try {
    const { data } = await SellingPricesServices.getAll(reqBody);
    result = data.sellingPrices;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getChartOfAccounts = async (reqBody) => {
  let result = [];
  try {
    const { data } = await ChartOfAccountService.getAll(reqBody);
    result = data;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getChartOfAccountList = async () => {
  let result = [];
  try {
    const { data } = await ChartOfAccountService.getList();
    result = data.rows;
  } catch (error) {
    console.log(error);
  }
  return result;
};

const getEdcRates = async (reqBody) => {
  let result = [];
  try {
    const { data } = await EdcServices.getAll(reqBody);
    result = data.edcRates;
  } catch (error) {
    console.log(error);
  }
  return result;
};

export default function* rootSaga() {
  yield all([
    fork(getAllMasterData),
    getAllBranch(),
    getAllChartOfAccounts(),
    getAllStockCategories(),
    getAllStockUnitsSaga(),
    getAllWarehouseSaga(),
    getAllSuppliersSaga(),
    getAllCustomersSaga(),
    getAllEdcRatesSaga(),
  ]);
}
