import { masterContants } from ".";

const initState = {
  branchs: [],
  chartOfAccounts: [],
  stockCategories: [],
  brands: [],
  stockUnits: [],
  sellingPrices: [],
  goods: [],
  inventories: [],
  goodUnits: [],
  warehouses: [],
  suppliers: [],
  customers: [],
  edcRates: [],
  masterData: {
    branchs: [],
    customers: [],
    goods: [],
    inventories: [],
    goodUnits: [],
    suppliers: [],
    sellingPrices: [],
    chartOfAccounts: [],
    coaList: [],
    edcRates: [],
    warehouses: [],
  },
};

export const masterReducers = (state = initState, action) => {
  switch (action.type) {
    case masterContants.SET_STATE_MASTER:
      return {
        ...state,
        ...action.payload,
      };
    case masterContants.SET_DEFAULT_MASTER: {
      return initState;
    }
    default:
      return state;
  }
};
