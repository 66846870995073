import FetchInterceptor from "configs/FetchInterceptor";

class PurchasePaymentServices {
  static async getAllDebtPayment(requestBody) {
    const apiUrl = `/core/v1/purchase/debt-payment/get/all`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getDebtPayment(requestBody) {
    const apiUrl = `/core/v1/purchase/debt-payment/get`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addDebtPayment(requestBody) {
    const apiUrl = `/core/v1/purchase/debt-payment/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async updateDebtPaymentDetailStatus(requestBody) {
    const apiUrl = `/core/v1/purchase/debt-payment/detail/update/status`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addDownPayment(requestBody) {
    const apiUrl = `/core/v1/purchase/payment/down-payment/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async addPayment(requestBody) {
    const apiUrl = `/core/v1/purchase/payment/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllPayment(requestBody) {
    const apiUrl = `/core/v1/purchase/payment/get/all`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getPaymentById(id) {
    const apiUrl = `/core/v1/purchase/payment/get/${id}`;
    const response = await FetchInterceptor.post(apiUrl);
    return response;
  }

  static async getPdf(id) {
    const apiUrl = `/core/v1/purchase/payment/debt/get/pdf/${id}`;
    const response = await FetchInterceptor.get(apiUrl, {
      responseType: "blob",
    });
    return response;
  }
}

export default PurchasePaymentServices;
