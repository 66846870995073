import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import { setMaster, masterContants } from "views/app-views/master/redux";
import { edcRatesContants } from "./Constants";
import { EdcServices } from "services";

export function* getAllEdcRatesSaga() {
  yield takeEvery(
    [masterContants.GET_ALL_MASTER, edcRatesContants.GET_ALL_EDC_RATES],
    function* ({ payload }) {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
        };
        const { data } = yield call(EdcServices.getAll, reqBody);
        yield put(setMaster({ edcRates: [...data.edcRates] }));
      } catch (error) {
        console.log("getAllEdcRatesSaga ", error);
      }
    },
  );
}

export default function* rootSaga() {
  yield all([fork(getAllEdcRatesSaga)]);
}
