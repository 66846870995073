import FetchInterceptor from "configs/FetchInterceptor";

class SalesPaymentServices {
  static async add(requestBody) {
    const apiUrl = `/core/v1/sales/payments/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAll(requestBody) {
    const apiUrl = "/core/v1/sales/payments/get/all";
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllPaymentMethod() {
    const apiUrl = "/core/v1/payment-methods/get/all";
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }

  static async addSalesRepayment(requestBody) {
    const apiUrl = `/core/v1/sales/repayments/add`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllPaymentDetail(requestBody) {
    const apiUrl = `/core/v1/sales/payments/details/get/all`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async updatePaymentDetail(requestBody) {
    const apiUrl = `/core/v1/sales/payments/details/update`;
    const response = await FetchInterceptor.post(apiUrl, requestBody);
    return response;
  }

  static async getAllPaymentMethodEdcConfig() {
    const apiUrl = "/core/v1/payment-methods/config/edc";
    const response = await FetchInterceptor.get(apiUrl);
    return response;
  }
}

export default SalesPaymentServices;
