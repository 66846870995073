import { all, takeEvery, put, fork, call, select } from "redux-saga/effects";
import { setMaster, masterContants } from "views/app-views/master/redux";
import { suppliersContants } from "./Constants";
import { SupplierServices } from "services";

export function* getAllSuppliersSaga() {
  yield takeEvery(
    [masterContants.GET_ALL_MASTER, suppliersContants.GET_ALL_SUPPLIERS],
    function* ({ payload }) {
      try {
        const getCurrentUser = (state) => state.user;
        const { currentUser } = yield select(getCurrentUser);

        const reqBody = {
          limit: 999999999999,
          offset: 0,
          branchId: currentUser.employee.branchId,
        };
        const { data } = yield call(SupplierServices.getAll, reqBody);
        yield put(setMaster({ suppliers: [...data.rows] }));
      } catch (error) {
        console.log("getAllSuppliersSaga ", error);
      }
    },
  );
}

export default function* rootSaga() {
  yield all([fork(getAllSuppliersSaga)]);
}
